<template>
  <div>
    <!-- <CRow v-if="isAdmin">
      <CCol>
        <CCard>
          <CCardBody> </CCardBody>
        </CCard>
      </CCol>
    </CRow> -->
    <CRow>
      <CCol>
        <CCard>
          <CCardBody>
            <CRow class="justify-content-start block-one">
              <!-- <CCol md="1">recording at</CCol> -->
              <CCol md="5">
                <CInput v-if="isAdmin" type="text" prepend="cmu it account : " required :value="cmuitaccount" @update:value="onInsertEmail" />
                <CInput v-if="!isAdmin" type="text" prepend="cmu it account : " required :value="cmuitaccount" readonly />
              </CCol>
              <CCol md="1">
                <CButton color="info" square v-on:click="filterByDate()">
                  Update
                </CButton>
              </CCol>
            </CRow>
            <CRow class="justify-content-start">
              <CCol md="1">recording at</CCol>
              <CCol md="4">
                <vc-date-picker v-model="range" is-range>
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="flex justify-center items-center">
                      <input :value="inputValue.start" v-on="inputEvents.start" class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300" />
                      <svg class="w-2 h-2 mx-2" style="width: 3%" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                      </svg>
                      <input :value="inputValue.end" v-on="inputEvents.end" class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300" />
                    </div>
                  </template>
                </vc-date-picker>
              </CCol>
              <CCol md="1">
                <CButton color="info" square v-on:click="filterByDate()">
                  Search
                </CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CCard>
          <CCardBody>
            <CDataTable
              :items="itemsReport"
              :fields="fieldsReport"
              :table-filter="{ placeholder: 'คำที่ต้องการค้นหา...', label: 'Search : ' }"
              items-per-page-select
              :items-per-page="20"
              hover
              sorter
              pagination
              border
              :sorterValue="{ column: 'timestamp', asc: false }"
            >
              <template #timestamp="{item}">
                <td>
                  {{ new Date(parseInt(item.timestamp) * 1000).toLocaleString("th-TH") }}
                </td>
              </template>
              <template #step1RecordingDownloaded="{item}">
                <td>
                  <CBadge :color="getBadge(item.step1RecordingDownloaded)">
                    {{ item.step1RecordingDownloaded }}
                  </CBadge>
                </td>
              </template>
              <template #step2CretedSessionUpload="{item}">
                <td>
                  <CBadge :color="getBadge(item.step2CretedSessionUpload)">
                    {{ item.step2CretedSessionUpload }}
                  </CBadge>
                </td>
              </template>
              <template #step3RecordingUploaded="{item}">
                <td>
                  <CBadge :color="getBadge(item.step3RecordingUploaded)">
                    {{ item.step3RecordingUploaded }}
                  </CBadge>
                </td>
              </template>
              <template #step4RecordingRemoved="{item}">
                <td>
                  <CBadge :color="getBadge(item.step4RecordingRemoved)">
                    {{ item.step4RecordingRemoved }}
                  </CBadge>
                </td>
              </template>
              <template #status="{item}">
                <td>
                  {{ getStatus(item.status) }}
                </td>
              </template>
              <template #video_source="{item}">
                <td class="py-2">
                  <CButton color="info" square size="sm" :href="item.webUrl">
                    open
                  </CButton>
                </td>
              </template>
              <template #manual_upload="{item}">
                <td class="py-2">
                  <CButton color="primary" variant="outline" square size="sm" @click="manualUpladVideo(item)">
                    upload
                  </CButton>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CCard> </CCard>
  </div>
</template>

<script>
import syncManangerApi from "@/services/apiServices"
import moment from "moment-timezone"

export default {
  name: "RecordingErrorPage",
  components: {},
  created() {},
  data() {
    return {
      enterEmail: "",
      startDate: new Date(),
      endDate: new Date(),
      range: { start: new Date(), end: new Date() },
      modelConfig: {
        type: "number",
        mask: "YYYY-MM-DD",
      },
      isAdmin: false,
      canBeEnterEmail: false,
      cmuitaccount: "",
      dateDayDefault: 518400,
      unixtimePerDate: 86400,
      timeZone: "Asia/Bangkok",
      timeFormat: "HH:mm DD-MM-YYYY",
      adminList: ["thapakorn.pe@cmu.ac.th", "nuttapong.pongkam@cmu.ac.th", "athicha.le@cmu.ac.th"],
      itemsReport: [],
      fieldsReport: [
        // { key: "id", label: "id", _style: "min-width:20%" },
        { key: "topic", label: "topic", _style: "min-width:20%" },
        { key: "cmuitaccount", label: "user", _style: "min-width:20%" },
        { key: "timestamp", label: "timestamp", _style: "min-width:10%" },
        { key: "datetime_uploaded", label: "upload date" },
        { key: "step1RecordingDownloaded", label: "1.downloaded", _style: "min-width:10%" },
        { key: "step2CretedSessionUpload", label: "2.session-created", _style: "min-width:10%" },
        { key: "step3RecordingUploaded", label: "3.uploaded", _style: "min-width:10%" },
        { key: "step4RecordingRemoved", label: "4.completed", _style: "min-width:10%" },
        // { key: "percent", label: "percent", _style: "min-width:10%" },
        { key: "status", label: "status", _style: "min-width:10%" },
        {
          key: "video_source",
          label: "open video",
          _style: "width:2%",
          sorter: false,
          filter: false,
        },
        {
          key: "manual_upload",
          label: "upload again",
          _style: "width:2%",
          sorter: false,
          filter: false,
        },
      ],
    }
  },
  watch: {
    range: function(val) {},
  },
  created() {
    this.checkAdminAccount()
    this.initDateTimeStart()
    this.initData()
  },
  methods: {
    async onInsertEmail(val) {
      this.cmuitaccount = val
    },
    async checkAdminAccount() {
      this.cmuitaccount = localStorage.getItem("email")
      let index = await this.adminList.findIndex((email) => email === this.cmuitaccount)
      if (index !== -1) {
        this.isAdmin = true
      } else {
        this.isAdmin = false
      }
    },
    async filterByDate() {
      let listOfTask = await this.getTaskByDate(this.range.start, this.range.end)
      this.itemsReport = listOfTask
    },
    async getTaskByDate(start, end) {
      let arrListReturn = []
      let startFormat = await moment.tz(start, this.timeZone).format("YYYY-MM-DD")
      let endFormat = await moment.tz(end, this.timeZone).format("YYYY-MM-DD")
      // let _option = `all`
      let listOfTasks = await syncManangerApi.getTaskListOfUser(this.cmuitaccount ,startFormat, endFormat)
      for (let task of listOfTasks) {
        let tempData = JSON.parse("{}")
        tempData = task
        if (task["topic"].length > 25) {
          tempData["topic"] = task["topic"].substr(0, 25)
        } else {
          tempData["topic"] = task["topic"]
        }
        tempData["datetime_record"] = moment.tz(task["start_time"], this.timeZone).format(this.timeFormat)
        tempData["datetime_uploaded"] = moment.tz(task["timedate"], this.timeZone).format(this.timeFormat)
        arrListReturn.push(tempData)
      }
      return arrListReturn
    },
    async initDateTimeStart() {
      let unixTimeNow = moment.tz(new Date(), this.timeZone).unix()
      let startUnixTime = unixTimeNow - this.dateDayDefault
      let endUnixTime = unixTimeNow
      this.range["start"] = new Date(
        moment
          .unix(startUnixTime)
          .tz(this.timeZone)
          .format()
      )
      this.range["end"] = new Date(
        moment
          .unix(endUnixTime)
          .tz(this.timeZone)
          .format()
      )
    },
    async initData() {
      let startFormat = await moment.tz(this.range.start, this.timeZone).format("YYYY-MM-DD")
      let endFormat = await moment.tz(this.range.end, this.timeZone).format("YYYY-MM-DD")
      // let _option = `all`
      let listOfTasks = await syncManangerApi.getTaskListOfUser(this.cmuitaccount,startFormat, endFormat)
      for (let task of listOfTasks) {
        let tempData = JSON.parse("{}")
        tempData = task
        if (task["topic"].length > 25) {
          tempData["topic"] = task["topic"].substr(0, 25)
        } else {
          tempData["topic"] = task["topic"]
        }
        tempData["datetime_record"] = moment.tz(task["start_time"], this.timeZone).format(this.timeFormat)
        tempData["datetime_uploaded"] = moment.tz(task["timedate"], this.timeZone).format(this.timeFormat)
        this.itemsReport.push(tempData)
      }
    },
    color(value) {
      let $color
      console.log("test-staging")
      if (value <= 25) {
        $color = "info"
      } else if (value > 25 && value <= 50) {
        $color = "success"
      } else if (value > 50 && value <= 75) {
        $color = "warning"
      } else if (value > 75 && value <= 100) {
        $color = "danger"
      }
      return $color
    },
    async manualUpladVideo(obj) {
      console.log("datetime ", this.range.start)
      console.log("datetime ", this.range.end)
      console.log("obj ", obj)
    },
    getStatus(status) {
      switch (status) {
        case "video-removed":
          return "completed"
        default:
          return status
      }
    },
    getBadge(status) {
      switch (status) {
        case false:
          return "danger"
        case true:
          return "success"
        default:
          return "secondary"
      }
    },
  },
}
</script>
<style scoped>
.block-one {
  margin-bottom: 1%;
}
</style>
